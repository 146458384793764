import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import { withTranslation } from "react-i18next";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
    title: "PLANLANMIŞ UYGULAMA",
    description:
      "Yaptığımız tüm projelerin fikir, tasarım ve uygulama aşamalarının hepsinde belirli bir plan dahilinde ve çok hassas şekilde çalışmaktayız…",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--13",
    title: "PROJELERİNİZ İÇİN MODERN TEKNİKLER",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--15",
    title: "SANATSAL BAKIŞ AÇISI",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
  },
];
class SliderTwo extends Component {
  render() {
    const { t } = this.props;

    const handleScrollDown = () => {
      window.scrollTo({
        top: window.scrollY + 1270,
        behavior: "smooth",
      });
    };
    return (
      <div className="slider-activation ">
        <Slider className=" " {...slideSlick}>
          {SlideList.map((value, index) => (
            <div
              className={`slide slide-style-2 fullscreen d-flex align-items-end justify-content-end bg_image  ${value.bgImage}`}
              key={index}
            >
              <div className="container ">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {/* SLİDER BİZ KİMİZ BUTONU START */}
                      <div
                        className="slide-btn mobil"
                        onClick={handleScrollDown}
                      >
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          style={{
                            padding: "10px 30px",
                            fontSize: "28px",
                            letterSpacing: 1,
                            fontWeight: 500,
                            color: "#fff",
                            WebkitTextStroke: "0.5px #fff",
                          }}
                        >
                          {t("rooms")}
                        </a>
                      </div>
                      <div
                        className="slide-btn mobilto mobilxl"
                        onClick={handleScrollDown}
                      >
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          style={{
                            padding: "10px 30px",
                            fontSize: "30px",
                            letterSpacing: 1,
                            fontWeight: 500,
                            color: "#fff",
                            backgroundColor: "#000000c0",
                            borderColor: "#000000c0",
                            WebkitTextStroke: "0.3px #000",
                          }}
                        >
                          {t("rooms")}
                        </a>
                      </div>
                      {/* SLİDER BİZ KİMİZ BUTONU END */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default withTranslation()(SliderTwo);
