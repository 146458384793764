// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
// Create Import File
import "./index.scss";
import PageScrollTop from "./component/PageScrollTop";
// Home layout
import MainDemo from "./home/MainDemo";
// Dark Home Layout
// Element Layout

import Contact from "./elements/Contact";
import error404 from "./elements/error404";

// Blocks Layout

import VideoPopup from "./blocks/VideoPopup";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

// PROJE PAGES
//Translate
import { I18nextProvider } from "react-i18next";
import i18n from "./home/i18n";
//Category Pages
import RoomForTwoPeople from "./proje-pages/room-for-two-people";
import DoubleRoom from "./proje-pages/double-room";
import RoomForThreePeople from "./proje-pages/room-for-three-pople";
import AboutUs from "./elements/AboutUs";
import Gallery from "./proje-pages/Gallery";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <I18nextProvider i18n={i18n}>
            <Switch>
              {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={MainDemo}
              />
              {/* Category Pages START */}

              {/* Category Pages END */}

              {/* PROJE PAGES*/}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/room-for-two-people`}
                component={RoomForTwoPeople}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/double-room`}
                component={DoubleRoom}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/room-for-three-people`}
                component={RoomForThreePeople}
              />

              {/* Element Layot */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/aboutus`}
                component={AboutUs}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/gallery`}
                component={Gallery}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={Contact}
              />

              {/* Blocks Elements  */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/video-popup`}
                component={VideoPopup}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/404`}
                component={error404}
              />
              <Route component={error404} />
            </Switch>
          </I18nextProvider>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
