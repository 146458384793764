import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // react için gerekli olan i18next'in react entegrasyonunu etkinleştirin
  .init({
    resources: {
      en: {
        translation: {
          aboutmainpage:
            "Zeynel Hotel, as the new face of the world-famous Konyaalti Beach, offers you service with a magnificent sea view. Across from our hotel, the Blue Flag Konyaalti Public Beach with its clear waters and unique natural beauty is waiting for you to accumulate beautiful memories.",
          allrights: "All rights reserved.",
          aboutus: "About Us",
          contact: "Contact",
          detail: "Detail",
          institutional: "Institutional",
          galery: "Gallery",
          whoweare: "Who Are We",
          home: "Home",
          references: "References",
          referencestextone: "Projects where dreams are possible",
          rooms: "Our Rooms",
          furniture: "Furniture",
          floorandfacade: "FLOOR AND FACADE COATINGS",
          woodenconstruction: "WOODEN CONSTRUCTION",
          woodenhouse: "Wooden House",
          graphicdesign: "Graphic Drawing and Design",
          roomForTwoPople: "Room For Two People",
          roomForThreePople: "Room For Three People",
          doubleRoom: "Double Room",
          transportFacilities: "Transportation Facilities",
          airport: "Airport 19 km",
          busStation: "Antalya Bus Station, 6.5 km",
          kaleiciAndMarinaDistance: "7 km to Kaleici and Marina",
          kemerDistance: "Kemer 38 km",
          hospitalDistance: "Antalya Education Research Hospital 3.5 km",
          migrosDistance: "Migros Shopping Mall 20 minutes on foot",
          aquariumDistance:
            "World's Largest Tunnel Aquarium 10 minutes on foot",
          stadyumDistance:
            "25 minutes on foot to Antalya Arena Football Stadium",
          pyramidDistance:
            "30 minutes on foot from Glass Pyramid Exhibition and Convention Center",
          taxiAndBus: "Taxis and buses for the city transport you everywhere.",
          featuresRooms: "Features of our rooms",
          featuresRoomsContent: "Our hotel has 5 floors and 38 standard rooms.",
          featuresRoomsContentt: "Our rooms have sea and city view.",
          television: "Television(LCD)",
          telephone: "Telephone",
          internet: "Internet Free WIFI",
          miniBar:
            "Minibar (Water is offered free of charge per person at the entrance)",
          safe: "Safe",
          bathroom: "Bathroom (Shower Cabin)",
          hairDryer: "Hair Dryer",
          toiletries: "Free toiletries",
          laminateFloor: "Laminate Floor",
          splitClimate: "Split Climate",
          featuresRoomsContentExit:
            "Check-in time to the rooms starts at 14:00.",
          featuresRoomsContentExitt:
            "Check-out time from the rooms is 12:00 at the latest.",
          hotelFeatures: "Hotel General Features",
          freeInternetLoby: "Free Internet in lobby",
          commonTvArea: "Common TV Area (Lobby)",
          elevator: "Elevator",
          receptionService: "24 Hour Reception Service",
          receptionSafety: "Reception Safety Deposit Box",
          swimmingPool: "Outdoor Swimming Pool",
          childrenPool: "Outdoor Children Pool",
          cafeteria: "Cafeteria",
          openBuffetBreakfast: "Open Buffet Breakfast",
          beachDistance: "Beach (50 meters)",
          serviceForFree: "Services Available for a Fee",
          doctor: "Doctor",
          airportTransfer: "Airport Transfer",
          laundryService: "Laundry Service",
          carRental: "Car Rental",
          roomService: "Room Service",
          socialMedia: "Social Media",
        },
      },
      tr: {
        translation: {
          aboutmainpage:
            "    Zeynel Hotel, Dünyaca ünlü Konyaaltı Sahili’nin yeni yüzü olarak, muhteşem deniz manzarası eşliğinde sizlere hizmet sunmaktadır. Otelimizin karşısında berrak suları ve eşsiz doğal güzelliği ile Mavi Bayraklı Konyaaltı Halk plajı güzel anılar biriktirmeniz için sizleri bekliyor. ",
          institutional: "Kurumsal",
          aboutus: "Hakkımızda",
          whoweare: "Biz Kimiz",
          home: "Anasayfa",
          references: "Referanslar",
          contact: "İletişim",
          allrights: "Tüm hakları saklıdır.",
          referencestextone: "  Hayallerin mümkün olduğu projeler",
          detail: "Detay",
          furniture: "Mobilya",
          rooms: "Odalarımız",
          galery: "Galeri",
          woodenconstruction: "AHŞAP KONSTRÜKSİYON",
          floorandfacade: "ZEMİN VE CEPHE KAPLAMALARI",
          woodenhouse: "Ahşap Ev",
          graphicdesign: "Grafik Çizim ve Tasarım",
          roomForTwoPople: "İki Kişilik Odalarımız",
          roomForThreePople: "Üç Kişilik Odalarımız",
          doubleRoom: "Çift Kişilik Odalarımız",
          transportFacilities: "Ulaşım İmkanları",
          airport: "Havalimanı 19 km",
          busStation: "Antalya Otogar, 6,5 km",
          kaleiciAndMarinaDistance: "Kaleiçi ve Yat Limanına 7 km",
          kemerDistance: "Kemer 38 km",
          hospitalDistance: "Antalya Eğitim Araştırma Hastanesi 3,5 km",
          migrosDistance: "Migros AVM yürüyerek 20 dakika",
          aquariumDistance:
            "Dünyanın En Büyük Tünel Akvaryumu yürüyerek 10 dakika",
          stadyumDistance:
            "Antalya Arena Futbol Stadyumuna yürüyerek 25 dakika",
          pyramidDistance:
            "Cam Piramit Fuar ve Kongre Merkezine yürüyerek 30 dakika",
          taxiAndBus: "Taksi ve şehir için otobüslerle tüm her yere ulaşım.",
          featuresRooms: "Odalarımızın Özellikleri",
          featuresRoomsContent:
            "Otelimiz 5 Katlı ve 38 standart odamız vardır.",
          featuresRoomsContentt: "Odalarımız Deniz ve Şehir manzaralıdır.",

          television: "Televizyon(LCD)",
          telephone: "Telefon",
          internet: "İnternet Ücretsiz WIFI",
          miniBar: "Minibar(Girişte Su Kişi Başı ücretsiz ikram edilir)",
          safe: "Safe(Kasa)",
          bathroom: "Banyo (Duşa Kabin)",
          hairDryer: "Saç Kurutma Makinesi",
          toiletries: "Ücretsiz banyo malzemeleri",
          laminateFloor: "Laminant Zemin",
          splitClimate: "Split Klima",
          featuresRoomsContentExit:
            "Odalara giriş saati 14:00’den itibaren başlar. Odalardan çıkış saati en geç 12:00’dir.",
          featuresRoomsContentExitt: "Odalardan çıkış saati en geç 12:00’dir.",
          hotelFeatures: "Otel Genel Özellikleri",
          freeInternetLoby: "Lobide Ücretsiz İnternet",
          commonTvArea: "Ortak Tv Alanı (Lobi)",
          elevator: "Asansör",
          receptionService: "24 Saat Resepsiyon Hizmeti",
          receptionSafety: "Resepsiyon Emanet Kasa",
          swimmingPool: "Açık Yüzme Havuzu",
          childrenPool: "Açık Çocuk Havuzu",
          cafeteria: "Kafeterya",
          openBuffetBreakfast: "Açık Büfe Kahvaltı",
          beachDistance: "Plaj (50 metre)",
          serviceForFree: "Ücret Karşılığında Alınabilecek Hizmetler",
          doctor: "Doktor",
          airportTransfer: "Havalimanı Tranferi",
          laundryService: "Çamaşır Yıkama Hizmeti",
          carRental: "Araç Kiralama",
          roomService: "Oda Servisi",
          socialMedia: "Sosyal Medya",
        },
      },
    },
    lng: "tr", // Varsayılan dil
    fallbackLng: "tr", // Yedek dil
    interpolation: {
      escapeValue: false, // HTML etiketlerini kaçırmamak için false olarak ayarlayın
    },
  });

export default i18n;
