import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaUserFriends,
  FaYoutube,
  FaPinterest,
  FaWhatsapp,
} from "react-icons/fa";
import { withTranslation } from "react-i18next";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/people/Zeynel-Hotel/100063681516567/",
  },
  // {
  //   Social: <FaLinkedinIn />,
  //   link: "https://tr.linkedin.com/in/akdeniz-konsept-05536a244",
  // },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/zeynelhotel/",
  },
  {
    Social: <FaWhatsapp />,
    link: "https://api.whatsapp.com/send/?phone=%2B905526170755&text=++Merhaba+%21+++&type=phone_number&app_absent=0",
  },

  // { Social: <FaYoutube />, link: "https://www.youtube.com/" },
  // { Social: <FaPinterest />, link: "https://tr.pinterest.com/akdenizkonsept/" },
];
class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--40 pb--60"
          style={{ backgroundColor: "#111a2a" }}
        ></div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--60 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1
                  className="textMobil"
                  style={{
                    textAlign: "center",
                    paddingBottom: "30px",
                    fontWeight: 300,
                    fontSize: "50px",
                  }}
                >
                  {t("contact")}
                </h1>
              </div>
              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("telephone")}</h4>
                    <p>
                      <a href="tel:+902423340755">+90 242 334 0755</a>
                      <h4 className="title">Gsm</h4>
                      <a href="tel:+905526170755">+90 552 617 0755</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner pb--70">
                    <h4 className="title">E-mail Adres</h4>
                    <p>
                      <a href="mailto:info@zeynelhotel.com">
                        info@zeynelhotel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
              {/*Start Single Social Media*/}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 mt_md--50 mt_sm--50  ">
                <div className="rn-address ">
                  <div className="icon ">
                    <FaUserFriends />
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("socialMedia")}</h4>

                    <div className="container">
                      <ul className="social-share rn-lg-size row liststyle pb-3  ">
                        {SocialShare.map((val, i) => (
                          <li key={i} className="p-2">
                            <a href={`${val.link}`}> {val.Social} </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*End Single Social Media*/}
              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Adres</h4>
                    <p>
                      Arapsuyu Mahallesi 610 Sokak No: 9 Konyaaltı / Antalya /
                      Türkiye
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Ahşap Fabrikası</h4>
                    <p>
                      Şafak Mh. Ahmet Hamdi Akseki Cad. No:36/D Kepez/ANTALYA
                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Mobilya Fabrikası</h4>
                    <p>
                      Şafak Mh. Ahmet Hamdi Akseki Cad. No:36/D Kepez/ANTALYA
                    </p>
                  </div>
                </div>
              </div> */}

              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        <div className="col-lg-12 col-sm-12 col-12 mt_mobile--30">
          <iframe
            title="google-maps"
            width="100%"
            height="500"
            frameBorder={0}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1595.8760528345483!2d30.65292563887662!3d36.872352038756354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c391a5f75d56fd%3A0x2546d8cdbf68db43!2sZeynel%20Hotel!5e0!3m2!1str!2str!4v1697018579760!5m2!1str!2str"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">
              measure acres/hectares on map
            </a>
          </iframe>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Contact);
