import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import "../elements/kurumsal.css";
import logo from "../../public/assets/images/logo/AkdenizKonseptLogo.png";
import { withTranslation } from "react-i18next";

class AboutUs extends Component {
  render() {
    const { t } = this.props;
    console.log(t, "Test");
    return (
      <React.Fragment>
        <div
          className="bg-color-custom text"
          style={{ backgroundColor: "#263951" }}
        >
          <PageHelmet pageTitle="kurumsal" />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <Breadcrumb title={"Kurumsal"} />
          {/* End Breadcrump Area */}
          <div className="about-area pb--10 pt--50">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-12">
                    {/* <h1
                    className="textMobil"
                    style={{
                      textAlign: "center",
                      paddingBottom: "30px",
                      fontWeight: 300,
                      fontSize: "50px",
                    }}
                  >
                    Zeynel Hotel
                  </h1> */}
                  </div>

                  <div className="col-lg-12">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h3
                          className="title subtitle text"
                          style={{ fontSize: "25px", fontWeight: 500 }}
                        >
                          {t("aboutus")}
                        </h3>
                        <p
                          className="description text"
                          style={{ fontSize: "17px" }}
                        >
                          {t("aboutmainpage")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Start Service Area */}
          <div className="about-area ptb--40  ">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-12">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            alignItems: "center",
                            gap: "90px",
                          }}
                        >
                          <div className="">
                            <h3
                              className="title subtitle text"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              {t("transportFacilities")}
                            </h3>
                            <ul
                              className="description"
                              style={{ fontSize: "17px" }}
                            >
                              <li>{t("airport")}</li>
                              <li>{t("busStation")}</li>
                              <li>{t("kaleiciAndMarinaDistance")}</li>
                              <li>{t("kemerDistance")}</li>
                              <li>{t("hospitalDistance")}</li>
                              <li>{t("migrosDistance")}</li>
                              <li>{t("aquariumDistance")}</li>
                              <li>{t("stadyumDistance")}</li>
                              <li>{t("pyramidDistance")}</li>
                              <li>{t("taxiAndBus")}</li>
                            </ul>
                          </div>
                          <div className="thumbnail ">
                            <img
                              height={300}
                              width={480}
                              style={{
                                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
                              }}
                              src="/assets/images/ProjePages/zeynel-hotel/antalya2.webp"
                              alt="Proje görseli"
                            />
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="thumbnail ">
                            <img
                              height={300}
                              width={500}
                              style={{
                                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
                              }}
                              src="/assets/images/ProjePages/zeynel-hotel/galeri/manzara1.jpg"
                              alt="Proje görseli"
                            />
                          </div>
                          <div>
                            <h3
                              className="title subtitle text"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              {t("featuresRooms")}
                            </h3>
                            <p
                              className="description text"
                              style={{ fontSize: "17px" }}
                            >
                              {t("featuresRoomsContent")}
                            </p>
                            <p
                              className="description text"
                              style={{ fontSize: "17px" }}
                            >
                              {t("featuresRoomsContentt")}
                            </p>
                            <ul
                              className="description"
                              style={{ fontSize: "17px" }}
                            >
                              <li>{t("television")}</li>
                              <li>{t("telephone")}</li>
                              <li>{t("internet")}</li>
                              <li>{t("miniBar")}</li>
                              <li>{t("safe")}</li>
                              <li>{t("bathroom")}</li>
                              <li>{t("hairDryer")}</li>
                              <li>{t("toiletries")}</li>
                              <li>{t("laminateFloor")}</li>
                              <li>{t("splitClimate")}</li>
                            </ul>
                            <p
                              className="description text"
                              style={{ fontSize: "17px" }}
                            >
                              {t("featuresRoomsContentExit")}
                            </p>
                            <p
                              className="description text"
                              style={{ fontSize: "17px" }}
                            >
                              {t("featuresRoomsContentExitt")}
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h3
                              className="title subtitle text"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              {t("hotelFeatures")}
                            </h3>

                            <ul
                              className="description"
                              style={{ fontSize: "17px" }}
                            >
                              <li>{t("freeInternetLoby")}</li>
                              <li>{t("commonTvArea")}</li>
                              <li>{t("elevator")}</li>
                              <li>{t("receptionService")}</li>
                              <li>{t("receptionSafety")}</li>
                              <li>{t("swimmingPool")}</li>
                              <li>{t("childrenPool")}</li>
                              <li>{t("cafeteria")}</li>
                              <li>{t("openBuffetBreakfast")} </li>
                              <li>{t("beachDistance")}</li>
                            </ul>
                          </div>
                          <div className="thumbnail">
                            <img
                              style={{
                                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
                              }}
                              height={300}
                              width={590}
                              src="/assets/images/ProjePages/zeynel-hotel/antalya1.jpg"
                              alt="Proje görseli"
                            />
                          </div>
                        </div>
                        <br />
                        <br />
                        <div>
                          <div>
                            <h3
                              className="title subtitle text"
                              style={{ fontSize: "20px", fontWeight: 500 }}
                            >
                              {t("serviceForFree")}
                            </h3>

                            <ul
                              className="description"
                              style={{ fontSize: "17px" }}
                            >
                              <li>{t("doctor")}</li>
                              <li>{t("airportTransfer")}</li>
                              <li>{t("laundryService")}</li>
                              <li>{t("carRental")}</li>
                              <li>{t("roomService")}</li>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(AboutUs);
